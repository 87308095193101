import { OverflownText } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FindBookingsQuery } from "../../api/operations.generated";
import PassengerCount from "../passengerCount/PassengerCount";
import PassengerNames, { PASSENGER_NAMES_LIMIT } from "../passengerNames/PassengerNames";
import * as css from "./PassengerInfo.scss";

type Props = {
  orderId: string;
  passengers: FindBookingsQuery["findBookings"][0]["passengers"];
};

const PassengerInfo: React.FC<Props> = ({ orderId, passengers }) => {
  const passengerNames = [...passengers].map(
    (passenger) => `${passenger.firstName} ${passenger.lastName}`
  );

  return (
    <div className={css.wrapper}>
      <PassengerCount passengers={passengers.length} />
      <OverflownText
        tooltipContent={
          <div>
            {passengerNames.map((name, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <p key={`${name}-${i}`}>{name}</p>
            ))}
          </div>
        }
        isTooltipShownDefault={passengers.length > PASSENGER_NAMES_LIMIT}
        extraClasses={css.tooltipWrapper}
      >
        <PassengerNames names={passengerNames} orderId={orderId} limitLines />
      </OverflownText>
    </div>
  );
};
export default PassengerInfo;
