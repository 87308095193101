import { datadogRum } from "@datadog/browser-rum";
import { Icon, IconAttention, IconDownload } from "@flixbus/honeycomb-icons-react";
import { Button, Heading } from "@flixbus/honeycomb-react";
import {
  HideMainNav,
  readURIParams,
  mpoPathnames as pathnames,
} from "@flixbus-phx/marketplace-common";
import { differenceInDays } from "date-fns";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import {
  fetchData,
  getFileName,
  getFileUrl,
  startDownload,
} from "../shared/helpers/downloadFileViaRest/downloadFileViaRest";
import * as css from "./BookingReports.scss";

enum DownloadStatus {
  OK = "200",
  NOT_FOUND = "404",
  UNSPECIFIED_ERROR = "unspecified error",
}

const BookingReports: React.FC = () => {
  const { search } = useLocation();
  const { token, date } = readURIParams(search);
  const [downloadStatus, setDownloadStatus] = React.useState<DownloadStatus>();

  if (date) {
    datadogRum.addAction("bookingReportsDownload", {
      date: new Date(date),
      daysDifference: differenceInDays(new Date(), new Date(date)),
    });
  }

  const downloadFile = async () => {
    try {
      const response = await fetchData("/bookings/download", {
        token,
      });

      const fileName = getFileName(response);
      const fileUrl = await getFileUrl(response);

      startDownload(fileName, fileUrl);

      setDownloadStatus(DownloadStatus.OK);
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === DownloadStatus.NOT_FOUND) {
          setDownloadStatus(DownloadStatus.NOT_FOUND);
        } else {
          setDownloadStatus(DownloadStatus.UNSPECIFIED_ERROR);
        }
      } else {
        setDownloadStatus(DownloadStatus.UNSPECIFIED_ERROR);
      }
    }
  };

  React.useEffect(() => {
    if (token) {
      downloadFile();
    } else {
      setDownloadStatus(DownloadStatus.UNSPECIFIED_ERROR);
    }
  }, []);

  return (
    <>
      <div className={css.container}>
        {downloadStatus === undefined && (
          <>
            <Icon InlineIcon={IconDownload} size={12} extraClasses={css.icon} />
            <Heading
              size={1}
              flushSpace
              extraClasses={`${css.headingAnimated} ${css.heading}`}
            >
              <FormattedMessage id="bookingReportsDownload.loading.title" />
              <span className={css.dot} />
            </Heading>

            <div className={css.text}>
              <FormattedMessage id="bookingReportsDownload.text.pleaseWait" />
            </div>
            <div className={css.text}>
              <FormattedMessage id="bookingReportsDownload.loading.text.bottom" />
            </div>
          </>
        )}

        {downloadStatus === DownloadStatus.OK && (
          <>
            <Icon InlineIcon={IconDownload} size={12} extraClasses={css.icon} />
            <Heading size={1} flushSpace extraClasses={`${css.heading}`}>
              <FormattedMessage id="bookingReportsDownload.success.title" />
            </Heading>

            <div className={css.text}>
              <FormattedMessage id="bookingReportsDownload.success.top" />
            </div>
            <div className={css.text}>
              <FormattedMessage id="bookingReportsDownload.text.pleaseWait" />
            </div>
          </>
        )}

        {downloadStatus === DownloadStatus.NOT_FOUND && (
          <>
            <Icon InlineIcon={IconAttention} size={12} extraClasses={css.icon} />
            <Heading
              size={1}
              flushSpace
              extraClasses={`${css.headingAnimated} ${css.heading}`}
            >
              <FormattedMessage id="bookingReportsDownload.404.title" />
            </Heading>

            <div className={css.text}>
              <FormattedMessage id="bookingReportsDownload.404.top" />
            </div>
            <div className={css.text}>
              <FormattedMessage id="bookingReportsDownload.404.bottom" />
            </div>
            <Button
              RouterLink={Link}
              to={pathnames.bookingInfo}
              extraClasses={css.button}
            >
              <FormattedMessage id="navigation.bookingInfo.button" />
            </Button>
          </>
        )}

        {downloadStatus === DownloadStatus.UNSPECIFIED_ERROR && (
          <>
            <Icon InlineIcon={IconAttention} size={12} extraClasses={css.icon} />
            <Heading
              size={1}
              flushSpace
              extraClasses={`${css.headingAnimated} ${css.heading}`}
            >
              <FormattedMessage id="general.AnErrorOccured" />
            </Heading>

            <div className={css.text}>
              <FormattedMessage id="bookingReportsDownload.error.top" />
            </div>
            <div className={css.text}>
              <FormattedMessage id="bookingReportsDownload.error.bottom" />
            </div>
            <Button onClick={() => window.location.reload()} extraClasses={css.button}>
              <FormattedMessage id="retry.button.text" />
            </Button>
          </>
        )}
      </div>
      <HideMainNav />
    </>
  );
};

export default BookingReports;
