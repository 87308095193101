import { Spinner } from "@flixbus/honeycomb-react";
import {
  DefaultInfoAlert,
  Feature,
  hasUserPermission,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { useIntl } from "react-intl";
import getFindRidesQueryVars from "../../../shared/helpers/getFindRidesQueryVars/getFindRidesQueryVars";
import useRideFilter, { rideFilterVar } from "../../../state/useRideFilter/useRideFilter";
import { useFindBookingsQuery } from "../../api/operations.generated";
import BookingList from "../../ui/bookingList/BookingList";
import DownloadConfigurator from "../downloadConfigurator/DownloadConfigurator";
import * as css from "./BookingInfoBody.scss";

const BookingInfoBody: React.FC = () => {
  const [rideFilter] = useRideFilter(rideFilterVar);
  const { formatMessage } = useIntl();

  const { data, loading, error } = useFindBookingsQuery({
    variables: getFindRidesQueryVars(rideFilter, true, false),
    fetchPolicy: "cache-first",
  });

  const createMarkup = (content: string) => {
    return { __html: content };
  };

  if (loading) {
    return (
      <div className={css.spinnerWrapper}>
        <Spinner />
      </div>
    );
  }

  if (data?.findBookings) {
    const bookings = data.findBookings;

    return (
      <>
        <div className={css.wrapper}>
          {bookings.length > 0 && (
            <p
              data-id="bookings-heading"
              className={css.heading}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={createMarkup(
                formatMessage(
                  { id: "bookingInfo.summary.headline" },
                  {
                    bookingCount: bookings.length,
                  },
                  { ignoreTag: true }
                )
              )}
            />
          )}
          {hasUserPermission(Feature.VIEW_BOOKING_REPORTS_DOWNLOAD) && (
            <DownloadConfigurator disabled={data.findBookings.length === 0} />
          )}
        </div>

        <BookingList bookings={bookings} />
      </>
    );
  }

  if (error) {
    return (
      <>
        <div className={css.errorWrapper}>
          <DefaultInfoAlert message={formatMessage({ id: "general.error.message" })} />
        </div>
      </>
    );
  }

  return <></>;
};

export default BookingInfoBody;
