import cx from "classnames";
import * as React from "react";
import * as css from "./PassengerNames.scss";

export const PASSENGER_NAMES_LIMIT = 2;

type Props = {
  names: Array<string>;
  orderId: string;
  limitLines: boolean;
};

const PassengerNames: React.FC<Props> = ({ names, orderId, limitLines }) => {
  const namesToDisplay = limitLines ? names.slice(0, PASSENGER_NAMES_LIMIT) : names;
  const hasNameOverflow = limitLines && names.length > PASSENGER_NAMES_LIMIT;

  return (
    <>
      {namesToDisplay.map((name, i, arr) => {
        const isLast = i === arr.length - 1;

        return (
          <p
            // eslint-disable-next-line react/no-array-index-key
            key={`${orderId}-${i}`}
            className={cx(css.name, isLast && hasNameOverflow && css.hasNameOverflow)}
          >
            {name}
          </p>
        );
      })}
      {hasNameOverflow && (
        <p className={css.nameOverflowIndicator}>
          +{names.length - PASSENGER_NAMES_LIMIT}
        </p>
      )}
    </>
  );
};

export default PassengerNames;
