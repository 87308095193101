import {
  DataReportType,
  ErrorPage,
  ErrorTypes,
  mpoPathnames as pathnames,
  hasUserPermission,
  Feature,
  readTrackingParams,
  TrackingChannel,
  dlPush,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { Route, Routes } from "react-router-dom";
import BookingInfo from "../bookingInfo/BookingInfo";
import BookingReports from "../bookingReports/BookingReports";
import Faq from "../faq/Faq";
import PartnerOverview from "../partneroverview/PartnerOverview";
import PaxList from "../paxList/PaxList";
import PowerBiReports from "../powerBiReports/PowerBiReports";
import RideOverview from "../ride/rideOverview/RideOverview";
import RedirectWrapper from "./RedirectWrapper";

export const Page404 = () => <ErrorPage type={ErrorTypes.e404} />;

const Router: React.FC = () => {
  const { channel } = readTrackingParams();

  if (channel === TrackingChannel.Mail) {
    dlPush({ event: "mail_channel" });
  }

  const returnPageOr404 = (
    page: JSX.Element,
    feature = Feature.USER_HAS_VIEW_ACCESS_TO_ALL_PAGES
  ): JSX.Element => {
    if (hasUserPermission(feature)) {
      return page;
    }
    return <Page404 />;
  };

  return (
    <Routes>
      <Route
        path={pathnames.capacityManager}
        element={returnPageOr404(
          <RedirectWrapper>
            <RideOverview />
          </RedirectWrapper>
        )}
      />
      <Route path={pathnames.faq} element={returnPageOr404(<Faq />)} />
      <Route
        path={pathnames.partnerOverview}
        element={returnPageOr404(<PartnerOverview />)}
      />
      <Route
        path={pathnames.bookingInfo}
        element={returnPageOr404(
          <RedirectWrapper>
            <BookingInfo />
          </RedirectWrapper>
        )}
      />
      <Route path={pathnames.bookingReportsDownload} element={<BookingReports />} />
      {Object.values(DataReportType).map((value) => {
        return (
          <Route
            path={`${pathnames.dataReports}/${value}`}
            key={value}
            element={returnPageOr404(
              <RedirectWrapper>
                <PowerBiReports report={value} />
              </RedirectWrapper>,
              Feature.VIEW_POWER_BI_REPORT
            )}
          />
        );
      })}
      <Route
        path={pathnames.paxList}
        element={returnPageOr404(<PaxList />, Feature.VIEW_PAX_LIST)}
      />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default Router;
