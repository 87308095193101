import { buildAuthorizationHeader, getToken } from "@flixbus-phx/marketplace-common";

export const fetchData = async (path: string, body: any): Promise<Response> => {
  const url = `${process.env.REST_API || "/api"}${path}`;
  const token = await getToken();

  const response = await fetch(url, {
    method: "POST",
    headers: {
      ...buildAuthorizationHeader(token ? token.idToken : "").headers,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    throw Error(response.status.toString());
  }

  return response;
};

export const getFileName = (response: Response) => {
  const headerContentDisposition = response.headers.get("content-disposition");

  if (headerContentDisposition === null) {
    return "booking-info.xls";
  }

  return headerContentDisposition.split("filename=")[1];
};

export const getFileUrl = async (response: Response) => {
  const data = await response.blob();

  return URL.createObjectURL(data);
};

export const startDownload = (fileName: string, fileUrl: string) => {
  const a = document.createElement("a");
  a.download = fileName;
  a.href = fileUrl;
  a.click();
  URL.revokeObjectURL(fileUrl);
};
