import { Spinner } from "@flixbus/honeycomb-react";
import { DefaultInfoAlert } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { useIntl } from "react-intl";
import { getContractPartner } from "../../helpers/selectedContractPartner/selectedContractPartner";
import * as css from "./DataPrivacyContractCheck.scss";
import {
  FindPartnerQueryVariables,
  useFindPartnerQuery,
} from "./api/operations.generated";
import NoDataPrivacyContractSignedMessage from "./ui/noDataPrivacyContractSignedMessage/NoDataPrivacyContractSignedMessage";

type Props = {
  LoadingAnimation?: React.ReactElement;
};

const DataPrivacyContractCheck: React.FC<Props> = ({
  children,
  LoadingAnimation = (
    <div className={css.wrapper}>
      <Spinner />
    </div>
  ),
}) => {
  const { formatMessage } = useIntl();

  const vars: FindPartnerQueryVariables = {
    contractPartnerId: getContractPartner().contractPartnerId,
  };

  const { data, loading, error } = useFindPartnerQuery({
    variables: vars,
  });

  if (loading) {
    return LoadingAnimation;
  }

  if (data?.findPartner) {
    const partnerData = data.findPartner;

    if (!partnerData.dataPrivacyContractSigned) {
      return <NoDataPrivacyContractSignedMessage />;
    }

    return <>{children}</>;
  }

  if (error) {
    if (error.message.includes("No partner found")) {
      return <NoDataPrivacyContractSignedMessage />;
    }
    return (
      <DefaultInfoAlert
        message={formatMessage({
          id: "general.findMarketplacePartnerQuery",
        })}
      />
    );
  }

  return <></>;
};

export default DataPrivacyContractCheck;
