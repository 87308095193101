import {
  Icon,
  IconArrowDown,
  IconArrowUp,
  IconInfo,
} from "@flixbus/honeycomb-icons-react";
import { Box, Tooltip } from "@flixbus/honeycomb-react";
import cx from "classnames";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import formatDate from "../../../shared/helpers/formatDate/formatDate";
import { FindBookingsQuery } from "../../api/operations.generated";
import BookingConnection from "../bookingConnection/BookingConnection";
import BookingContacts from "../bookingContacts/BookingContacts";
import BookingTag from "../bookingTag/BookingTag";
import PassengerCount from "../passengerCount/PassengerCount";
import PassengerNames from "../passengerNames/PassengerNames";
import * as css from "./BookingCard.scss";

type Props = {
  booking: FindBookingsQuery["findBookings"][number];
};

const BookingCard: React.FC<Props> = ({ booking }) => {
  const [extended, setExtended] = React.useState(false);

  const { passengers } = booking;

  const passengerNames = [...passengers].map(
    (passenger) => `${passenger.firstName} ${passenger.lastName}`
  );

  return (
    <Box small>
      <div
        data-id="booking-card-grid"
        key={`${booking.ride.id}${booking.orderId}`}
        className={cx(css.base, extended ? css.gridExtended : css.grid)}
      >
        <div className={css.statusLine}>
          <BookingTag orderStatus={booking.orderStatus} />
          <div className={css.lineCode}>{booking.lineCode}</div>
        </div>

        <div className={css.orderReference}>
          <div className={css.label}>
            <FormattedMessage id="general.orderReference" />
            <Tooltip
              id="bookingInfo.orderReferenceHint"
              position="right"
              content={<FormattedMessage id="bookingInfo.orderReferenceHint" />}
            >
              <Icon InlineIcon={IconInfo} />
            </Tooltip>
          </div>
          <div>{booking.orderReference}</div>
        </div>

        <div className={css.oldBookingId}>
          <div className={css.label}>
            <FormattedMessage id="general.oldBookingId" />
          </div>
          <div>{booking.orderId}</div>
        </div>

        <div className={css.travelDate}>
          <div className={css.label}>
            <FormattedMessage id="general.travelDate" />
          </div>
          {formatDate(booking.departureTime, "iii, MMM d, y")}
        </div>

        <div className={css.connection}>
          <div className={css.label}>
            <FormattedMessage id="general.route" />
          </div>
          <BookingConnection booking={booking} />
        </div>

        <div className={css.passengerInfo}>
          <div className={css.label}>
            <FormattedMessage id="general.passengers" />
          </div>
          <div className={css.passengerInfoWrapper}>
            <PassengerCount passengers={passengers.length} />
            <div className={css.nameWrapper}>
              <PassengerNames
                names={passengerNames}
                orderId={booking.orderId}
                limitLines={!extended}
              />
            </div>
          </div>
        </div>

        {extended && (
          <>
            <div className={css.contacts}>
              <div className={css.label}>
                <FormattedMessage id="general.contacts" />
              </div>
              <BookingContacts
                contactEmail={booking.contactEmail}
                passengers={passengers}
                showEmailFully
              />
            </div>

            <div className={css.interconnection}>
              <div className={css.label}>
                <FormattedMessage id="general.interconnection" />
              </div>
              {booking.interconnection ? (
                <FormattedMessage id="general.yes" />
              ) : (
                <FormattedMessage id="general.no" />
              )}
            </div>
          </>
        )}
      </div>
      <Box
        data-id="booking-card-extend-button"
        extraClasses={css.box}
        onClick={() => setExtended((prevState) => !prevState)}
      >
        {!extended ? (
          <div className={css.button}>
            <div>
              <FormattedMessage id="general.label.viewMore" />
            </div>
            <Icon InlineIcon={IconArrowDown} size={4} />
          </div>
        ) : (
          <div className={css.button}>
            <div>
              <FormattedMessage id="general.label.viewLess" />
            </div>
            <Icon InlineIcon={IconArrowUp} size={4} />
          </div>
        )}
      </Box>
    </Box>
  );
};

export default BookingCard;
