@use "variables" as *;

.name {
  flex: 1;
  word-break: break-word;
}

.hasNameOverflow {
  width: 85%;
  float: left;
}

.nameOverflowIndicator {
  float: right;
}
